import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import $ from 'jquery';
import 'turn.js';
import './Flipbook.css';
import { Paper, Slide } from '@material-ui/core';
import { BusinessUnitContext } from '../OfferDefinition/BusinessUnitContext';
import _, { find, isUndefined } from 'lodash';
import FlipbookPageGradient from './Utiles/FlipbookPageGradient';
import { getEcomCart, isSessionValid } from '../../services/ecomCart.service';
import MyCart from './MyCart/MyCart';
import { UserContext } from '../../hooks/UserContext';
import FlipbookAppBar from './FlipbookAppBar/FlipbookAppBar';
import { EditorPanel } from './Editors/SidePanel/EditorPanel';
import { FlipbookNavigationBar } from './FlipbookNavigationBar';
import { Flipbook } from './Flipbook';
import { isFeatureEnabled } from '../../services/feature-check.service';
import { FlipbookImageTagger } from './Utiles/FeatureConstants';
import { TaggerImagePanelContext } from '../Flipbook/Hooks/TaggerImagePanelContext';
import { FlipbookCartContext } from './Hooks/FlipbookCartContext';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FlipbookV2Service from '../../services/Flipbook/flipbookV2.service';
import get from 'lodash/get';
import { FlipbookContext } from './Hooks/FlipbookContext';
import { ImageType } from '../../models/FlipbookV2/TileData/TileDataImage.model';
import isEmpty from 'lodash/isEmpty';
import { FlipbookPagesContext } from './Hooks/FlipbookPagesContext';
import { createCartInEcom, updateFlipbookCart } from './Utiles/FlipbookUtiles';
import { FlipbookProductsContext } from './Hooks/FlipbookProductsContext';

const useStyles = makeStyles(theme => ({
  root: {
    height: 180
  },
  wrapper: {
    width: 100 + theme.spacing(2)
  },
  paper: {
    zIndex: 1,
    position: 'relative',
    margin: theme.spacing(1),
    gridArea: '1 / 2 / 2 / 3'
  }
}));

function useKey(data) {
  const [key, setKey] = useState(1);
  const [prevData, setPrevData] = useState(data);

  if (data !== prevData) {
    setKey(key + 1);
    setPrevData(data);

    // return key + 1
  }

  return key;
}

export const FlipbookContainer = ({
  flipbookIdOrToken,
  isCustomerView,
  isEditMode,
  storeDetails,
  setCurrentStore,
  currentStore,
  hasPermission
}) => {
  const classes = useStyles();
  const userInfo = useContext(UserContext);
  const tenantName =
    _.get(userInfo, 'userInfo.esKey') ||
    _.get(userInfo, 'userInfo.tenant.esKey');
  const storeCode = _.get(currentStore, 'store', '');
  const [page, setPage] = useState(1);
  const flipbookEl = useRef(null);
  const [cartItems, setCartItems] = React.useState([]);
  const [removedItems, setRemovedItems] = useState([]);
  const [isInvalidCart, setIsInvalidCart] = useState(false);
  const [isEditPanelVisible, setIsEditPanelVisible] = React.useState();
  const [totalPageCount, setTotalPageCount] = React.useState(0);
  const [flipbookPages, setFlipbookPages] = useState(undefined);
  const [isEditPanelOpen, setIsEditPanelOpen] = React.useState(false);
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = React.useState([]);
  const [innerPageCount, setInnerPageCount] = React.useState(0);
  const [isSessionValidated, setIsSessionValidated] = React.useState(false);
  const [isSessionActivated, setIsSessionActivated] = React.useState(false);
  const [sidePanelAction, setSidePanelAction] = React.useState();
  const { business } = useContext(BusinessUnitContext);
  const { id: businessId, productHierarchyNew: productHierarchy } = business;
  const flipbookContext = useContext(FlipbookContext);
  const flipbookData = flipbookContext.flipbookContext;
  const flipbookCartContext = useContext(FlipbookCartContext);
  const { setFlipbookPages: contextSetFlipbookPages } = useContext(
    FlipbookProductsContext
  );
  const flipbookElKey = useKey(flipbookData);
  function updateCart(ecomResponse) {
    setCartItems(updateFlipbookCart(ecomResponse));
  }

  useEffect(() => {
    if (!isUndefined(flipbookPages)) {
      return;
    }
    if (isCustomerView) {
      FlipbookV2Service.getFlipbookPagesByToken(flipbookIdOrToken).then(
        response => {
          if (response.status === 'success') {
            setInnerPageCount(response.data.length);
            setFlipbookPages(response.data);
            contextSetFlipbookPages(response.data);
          }
        }
      );
    } else {
      const mode = isEditMode ? 'edit' : 'view';
      FlipbookV2Service.getFlipbookPages(
        get(flipbookData, 'id', ''),
        mode
      ).then(response => {
        if (response.status === 'success') {
          setInnerPageCount(response.data.length);
          setFlipbookPages(response.data);
          contextSetFlipbookPages(response.data);
        }
      });
    }
  }, [
    contextSetFlipbookPages,
    flipbookData,
    flipbookIdOrToken,
    flipbookPages,
    isCustomerView,
    isEditMode
  ]);

  useEffect(() => {
    if (flipbookEl.current) {
      const currentViewPages = $(flipbookEl.current).turn('view');
      setCurrentPage(currentViewPages);
    }
  }, [page]);

  useEffect(() => {
    setIsSessionValidated(false);
    if (
      isUndefined(businessId) ||
      flipbookCartContext.isSessionActivated ||
      !isSessionActivated ||
      isEmpty(tenantName) ||
      _.isEmpty(storeCode) ||
      isEmpty(flipbookData.checkoutOption)
    ) {
      return;
    }
    const tokenType = localStorage.getItem('tokenType');
    isSessionValid(
      tenantName,
      businessId,
      flipbookData.checkoutOption,
      tokenType
    ).then(res => {
      const requestStatus = _.get(res, 'status', 'failure');
      if (requestStatus !== 'success') {
        localStorage.clear();
      }
      isCustomerView && setIsSessionValidated(true);
    });
  }, [
    flipbookData.checkoutOption,
    isSessionActivated,
    tenantName,
    businessId,
    isCustomerView,
    storeCode,
    flipbookCartContext.isSessionActivated
  ]);

  useEffect(() => {
    if (
      _.isEmpty(storeCode) ||
      !isCustomerView ||
      isUndefined(businessId) ||
      isSessionValidated
    ) {
      return;
    }
    const token = localStorage.getItem('token') || '';
    if (!_.isEmpty(token)) {
      getEcomCart(businessId).then(res => {
        if (!_.has(res, 'error')) {
          updateCart(res);
          setIsSessionActivated(true) &&
            flipbookCartContext.setIsSessionActivated(true);
          return;
        } else {
          createCartInEcom(
            tenantName,
            storeCode,
            flipbookData.checkoutOption
          ).then(r => {
            setIsSessionActivated(true) &&
              flipbookCartContext.setIsSessionActivated(true);
            return r;
          });
        }
      });
      return;
    }
    createCartInEcom(
      tenantName,
      storeCode,
      flipbookData.checkoutOption,
      businessId
    ).then(r => {
      setIsSessionActivated(true) &&
        flipbookCartContext.setIsSessionActivated(true);
      return r;
    });
  }, [
    businessId,
    currentPage,
    flipbookCartContext,
    flipbookData.checkoutOption,
    isCustomerView,
    isSessionValidated,
    storeCode,
    tenantName
  ]);
  useEffect(() => {
    const currentViewPageData = _.map(currentPage, pageNumber => {
      if (pageNumber < 2) {
        return;
      }
      const currentPageData = _.get(flipbookPages, pageNumber - 2, {});
      const layoutTiles = get(currentPageData, 'flipbookLayoutTile', []);
      const taggerTile = find(layoutTiles, tile => {
        return get(tile, 'tileData.imageType', '') === ImageType.TAGGER;
      });
      return !isEmpty(taggerTile);
    });
    const isTaggerImageAvailable = currentViewPageData.includes(true);
    setIsEditPanelVisible(
      isTaggerImageAvailable &&
        isEditMode &&
        !FlipbookPageGradient.isMobileView()
    );
  }, [currentPage, isEditMode, flipbookPages]);

  const goToPageNumber = pageNumber => {
    $(flipbookEl.current).turn('page', pageNumber);
  };

  const updatePageCallback = useCallback(() => {
    setFlipbookPages(undefined);
  }, []);

  return (
    <TaggerImagePanelContext.Provider
      value={{
        taggerImagePanelInfo: sidePanelAction,
        setTaggerImagePanelInfo: setSidePanelAction
      }}
    >
      <FlipbookCartContext.Provider
        value={{
          cartItems: cartItems,
          setCartItems: setCartItems,
          storeCode: storeCode,
          isSessionActivated: isSessionActivated,
          removedItems: removedItems,
          setRemovedItems: setRemovedItems,
          isInvalidCart: isInvalidCart,
          setIsInvalidCart: setIsInvalidCart
        }}
      >
        <FlipbookPagesContext.Provider
          value={{
            updateFlipbookPages: updatePageCallback
          }}
        >
          <div className={'flipbookContainer'} key={flipbookElKey}>
            <div className={'flipbook-bar-container'}>
              {
                <FlipbookAppBar
                  flipbookPages={flipbookPages}
                  allProducts={[]}
                  isEditMode={isEditMode}
                  goToPageNumber={goToPageNumber}
                  productHierarchy={productHierarchy}
                  isEditPanelVisible={
                    isEditPanelVisible && isFeatureEnabled(FlipbookImageTagger)
                  }
                  isEditPanelOpen={isEditPanelOpen}
                  setIsEditPanelOpen={setIsEditPanelOpen}
                  flipbookIdOrToken={flipbookIdOrToken}
                  currentStore={currentStore}
                  isCustomerView={isCustomerView}
                  setCurrentStore={setCurrentStore}
                  storeDetails={storeDetails}
                  flipbookEl={flipbookEl}
                />
              }
            </div>
            <div
              className={
                isCustomerView || isEditPanelOpen
                  ? 'flipbook-container-sidepanel-view'
                  : 'flipbook-container'
              }
            >
              <Flipbook
                flipbookPages={flipbookPages}
                flipbookEl={flipbookEl}
                setPage={setPage}
                page={page}
                setTotalPageCount={setTotalPageCount}
                isEditMode={isEditMode}
                goToPageNumber={goToPageNumber}
                innerPageCount={innerPageCount}
                storeDetails={storeDetails}
                isCustomerView={isCustomerView}
                flipbookIdOrToken={flipbookIdOrToken}
                currentStore={currentStore}
                setCurrentStore={setCurrentStore}
                sidePanelAction={sidePanelAction}
                hasPermission={hasPermission}
              />
              <FlipbookNavigationBar
                page={page}
                totalPageCount={totalPageCount}
                flipbookEl={flipbookEl}
                isCustomerView={isCustomerView}
                cartItems={cartItems}
              />
              {isCustomerView && !FlipbookPageGradient.isMobileView() && (
                <MyCart
                  storeDetails={storeDetails}
                  flipbookIdOrToken={flipbookIdOrToken}
                  currentStore={currentStore}
                />
              )}
              {
                <Slide
                  direction="left"
                  in={isEditPanelOpen}
                  mountOnEnter
                  unmountOnExit
                >
                  <Paper elevation={1} className={classes.paper}>
                    <EditorPanel
                      sidePanelAction={(
                        actionType,
                        message,
                        key,
                        actionState
                      ) =>
                        setSidePanelAction({
                          actionType,
                          message,
                          key,
                          actionState
                        })
                      }
                    />
                  </Paper>
                </Slide>
              }
            </div>
          </div>
        </FlipbookPagesContext.Provider>
      </FlipbookCartContext.Provider>
    </TaggerImagePanelContext.Provider>
  );
};

export default FlipbookContainer;
